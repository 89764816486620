import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { isStripeCheckoutEnabled } from '@redux/actions/payment/stripeActions';

export const getStripeCheckoutFlowEnabledSelector = (state: RootState) => isStripeCheckoutEnabled(state);

const addNewCardState = createSelector([getStripeCheckoutFlowEnabledSelector], (stripeCheckoutFlowEnabled) => ({
  stripeCheckoutFlowEnabled,
}));

export default addNewCardState;
