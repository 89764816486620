import { PaymentMethodType, paymentMethodTypes, StripeBillingDetails } from '@JohnLewisPartnership/checkout-psp-ui';

// Design System
import validate from 'jl-design-system/form/validation/validate';

// Types
import { AppDispatch, RootState } from 'types/RootState.types';

// Config
import { setCardFormValidationStatus, validator } from './paymentActions';
import { UPDATE_BILLING_ADDRESS } from '@constants/actionConstants';
import errorCodeConstants from '@constants/errorCodeConstants';
import featureConstants from '@constants/featureConstants';
import { updateBillingAddress } from '@redux/actions/payment/cardPaymentActions';
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import { getFormattedAddressFromStripe } from '@utils/address/addressHelpers';
import getBillingDetailsFormConfig from '@utils/form/configs/billingAddress';
import { isEmptyObject } from '@utils/object';

export const isStripeCheckoutEnabled = (state: RootState) =>
  isFeatureActive(state, featureConstants.STRIPE_CHECKOUT_ENABLED);

export const stripeOnButtonClickHandler = () => async (dispatch: AppDispatch) => {
  const billingDetailsValid = await dispatch(validator.validateBillingAddress());

  if (!billingDetailsValid) {
    dispatch(setCardFormValidationStatus(false));
    return false;
  }
  return true;
};

export const stripeValidateBillingDetailsHandler =
  ({
    billingDetails,
    paymentMethodType,
  }: {
    billingDetails: StripeBillingDetails;
    paymentMethodType: PaymentMethodType;
  }) =>
    async (dispatch: AppDispatch) => {
      const isApplePay = paymentMethodType === paymentMethodTypes.APPLE_PAY;
      const isGooglePay = paymentMethodType === paymentMethodTypes.GOOGLE_PAY;

      const formattedAddress = getFormattedAddressFromStripe(billingDetails);
      const validateAddressResult = validate(
        {
          ...formattedAddress?.address,
          ...formattedAddress?.addressee,
          phoneNumber: formattedAddress?.phoneNumber,
        },
        {
          config: getBillingDetailsFormConfig({
            countryCode: formattedAddress?.address?.countryCode,
            actions: {},
            enableGBCounty: !!formattedAddress?.address?.countyStateOrProvince,
          }),
        },
      );

      if (isEmptyObject(validateAddressResult)) {
        const updateBillingAddressResponse = await dispatch(
          updateBillingAddress({
            body: formattedAddress,
            ...(isApplePay && { isApplePaySelected: true }),
            ...(isGooglePay && { isGooglePay: true }),
          }),
        );

        if (updateBillingAddressResponse.type === `${UPDATE_BILLING_ADDRESS}.SUCCESS`) {
          return billingDetails;
        }
      }

      if (isGooglePay) {
        return new Promise((resolve) => {
          dispatch({
            type: `${UPDATE_BILLING_ADDRESS}.FAILED`,
            error: {
              code: errorCodeConstants.INCOMPLETE_ADDRESS_DETAILS,
            },
            googlePayBillingAddress: formattedAddress,
            googlePayBillingAddressResolve: resolve,
          });
        });
      }

      return {};
    };
